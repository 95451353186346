<script setup lang="ts">
import { NewTask, Task, TaskComment, UpdateTask } from '@aedifion.io/aedifion-api'
import { computed, reactive } from 'vue'
import { ActionMenuItem } from '@/components/ActionMenu.vue'
import AnalysisResultSelector from '../AnalysisResultSelector.vue'
import type { ComponentInProjectListItemData } from '@/vuex/components_in_project/types'
import ComponentSelector from '../ComponentSelector.vue'
import { formatValue } from '@/filters/formatting'
import KebabMenu from '@/components/KebabMenu.vue'
import moment from 'moment'
import PriorityIcon from '@/components/TaskList/PriorityIcon.vue'
import PriorityLabel from '@/components/TaskList/PriorityLabel.vue'
import StatusChip from '@/components/TaskList/StatusChip.vue'
import { storeToRefs } from 'pinia'
import TaskComments from '../TaskComments/TaskComments.vue'
import { useChecklistStore } from '@/stores/views/Optimization/Checklist'
import { useDate } from 'vuetify'
import { useI18n } from 'vue-i18n'
import UserSelector from '@/views/Optimization/Checklist/UserSelector.vue'
import { useUserStore } from '@/stores/user'
import TextEditor from '@/components/TextEditor/TextEditor.vue'
import { type JSONContent } from '@tiptap/vue-3'
import { getPriorityColor } from '@/utils/helpers/tasks'

// #region Initializations for props and emits
interface Props {
  analysisResultId?: string|null,
  isCompanyAdmin: boolean,
  isReadOnly: boolean,
  selectedComponent?: ComponentInProjectListItemData,
  task: Task|null,
  value: boolean
}

const props = defineProps<Props>()

const emits = defineEmits<{
  (event: 'close'): void,
  (event: 'create-task', newTask: NewTask): void,
  (event: 'delete-task'): void,
  (event: 'update-task', updatedTask: UpdateTask): void
}>()

const { t } = useI18n()
const date = useDate()
const { userDetails: currentUser } = storeToRefs(useUserStore())
// #endregion

// #region Task data and its related computed properties
const taskData = reactive({
  analysis_result_id: getAnalysisId(),
  assignee: props.task?.assignee ?? undefined,
  assignee_user_id: props.task?.assignee?.id ?? undefined,
  componentinproject: getComponentInProject(),
  description: props.task?.description ?? '',
  end: props.task?.end ? moment(props.task.end).toDate() : undefined,
  priority: props.task?.priority ?? Task.PriorityEnum.Medium,
  reporter: props.task?.reporter ?? undefined,
  start: props.task?.start ? moment(props.task.start).toDate() : undefined,
  status: props.task?.status ?? Task.StatusEnum.Open,
  title: props.task?.title ?? '',
})

const descriptionData = computed<JSONContent|null>({
  get: () => {
    if (props.task?.description === undefined) {
      return null
    }

    let description: JSONContent|null = null
    try {
      description = JSON.parse(taskData.description)
    } catch {
      description = {
        type: 'doc',
        content: [
          {
            type: 'paragraph',
            content: [
              {
                type: 'text',
                text: taskData.description,
              },
            ],
          },
        ],
      }
    }

    return description
  },
  set: (value) => {
    taskData.description = JSON.stringify(value)
  },
})

function getAnalysisId () {
  if (props.task?.analytics_result?.[0]?.id) {
    return props.task.analytics_result[0].id
  }

  if (props.analysisResultId) {
    return props.analysisResultId
  }

  return undefined
}

function getComponentInProject () {
  if (props.task?.componentinproject) {
    return props.task.componentinproject[0]
  }

  if (props.selectedComponent) {
    return props.selectedComponent
  }

  return undefined
}

const costSavings = computed<string>(() => {
  if (props.task?.savings_potential === undefined) {
    return ''
  }

  return formatValue(props.task?.savings_potential?.value, { displayUnit: true, integer: true, unit: '€' })
})

const isNewTaskValid = computed<boolean>(() => taskData.title !== '')

const isEditMode = computed<boolean>(() => props.task !== null)

const datePickerDialogs = reactive({
  end: false,
  start: false,
})
// #endregion

// #region Task comments
const checklistStore = useChecklistStore()

const comments = computed<TaskComment[]>(() => checklistStore.taskComments !== null ? checklistStore.taskComments : [])

if (isEditMode.value) {
  checklistStore.getTaskComments(props.task!.id!)
}
// #endregion

// #region Items for v-select 'priority' and 'status'
const menuItems = computed<ActionMenuItem[]>(() => {
  return [{
    icon: 'far fa-trash',
    id: 'delete',
    label: t('delete_taskData'),
  }]
})

const priorityOptions = [Task.PriorityEnum.High, Task.PriorityEnum.Medium, Task.PriorityEnum.Low]
const statusOptions = [
  {
    text: t('status_options.open'),
    value: Task.StatusEnum.Open,
  },
  {
    text: t('status_options.done'),
    value: Task.StatusEnum.Done,
  },
  {
    text: t('status_options.doing'),
    value: Task.StatusEnum.Doing,
  },
  {
    text: t('status_options.review'),
    value: Task.StatusEnum.Review,
  },
  {
    text: t('status_options.rejected'),
    value: Task.StatusEnum.Rejected,
  },
  {
    text: t('status_options.failed'),
    value: Task.StatusEnum.Failed,
  },
]

// #endregion

// #region CRUD operations for task
function onCreateTask () {
  emits('create-task', extractProperties())
}

function handleUpdateTask () {
  if (isEditMode.value) {
    emits('update-task', extractProperties())
  }
  if (datePickerDialogs.start || datePickerDialogs.end) {
    datePickerDialogs.start = false
    datePickerDialogs.end = false
  }
}

function extractProperties () {
  return {
    analytics_result: taskData.analysis_result_id ? [taskData.analysis_result_id] : undefined,
    assignee_user_id: taskData.assignee?.id,
    componentinproject: taskData.componentinproject?.id ? [taskData.componentinproject.id] : undefined,
    description: taskData.description,
    end: taskData.end,
    priority: taskData.priority,
    reporter_user_id: taskData.reporter?.id ?? currentUser.value!.id!,
    start: taskData.start,
    status: taskData.status,
    title: taskData.title,
  }
}
// #endregion
</script>

<template>
  <div>
    <v-dialog
      :model-value="value"
      max-width="640px"
      width="85%"
      @click:outside="emits('close')"
      @keydown.esc.prevent="emits('close')"
      @update:model-value="emits('close')"
    >
      <v-card>
        <v-card-title
          class="px-4 pt-6 pb-0 mb-5 d-flex tw-align-middle tw-items-center tw-justify-center"
        >
          <v-select
            v-model="taskData.priority"
            :items="priorityOptions"
            :disabled="isReadOnly"
            hide-selected
            hide-details
            :menu-props="{
              'offset': [0, 0]
            }"
            menu-icon="fal fa-angle-down"
            variant="plain"
            single-line
            class="tw-w-fit tw-grow-0 pt-0 tw-mt-[-20px] priority-selector"
            data-testid="priority-selector"
            @update:model-value="handleUpdateTask"
          >
            <template #item="{ item, props: prioritySelectionProps }">
              <v-list-item
                class="d-flex px-2"
                v-bind="prioritySelectionProps"
              >
                <template #prepend>
                  <PriorityIcon :priority="item.raw" />
                </template>
                <PriorityLabel :priority="item.raw" />
                <template #title />
              </v-list-item>
            </template>
            <template #selection="{ item }">
              <v-list-item class="d-flex px-2">
                <template #prepend>
                  <PriorityIcon
                    :priority="item.raw"
                    data-testid="task-priority-icon"
                  />
                </template>
                <PriorityLabel :priority="item.raw" />
              </v-list-item>
            </template>
          </v-select>
          <v-spacer />
          <KebabMenu
            v-if="task && !isReadOnly"
            data-testid="task-kebab-menu"
            :items="menuItems"
            small
            @delete-option-click="emits('delete-task')"
          />
          <v-btn
            color="neutral-darken3"
            icon
            size="small"
            variant="text"
            @click="emits('close')"
          >
            <v-icon size="12">
              far fa-xmark-large
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-container
          class="px-3 pt-0 pb-6"
        >
          <div v-if="task">
            <v-textarea
              v-model="taskData.title"
              :disabled="isReadOnly"
              variant="plain"
              density="compact"
              rows="1"
              auto-grow
              hide-details
              no-resize
              data-testid="task-title"
              :class="[`text-${getPriorityColor(taskData.priority)}-darken2 px-1 py-0 custom-input h4-text tw-h-fit`]"
              @blur="handleUpdateTask"
            />
          </div>
          <v-text-field
            v-else
            v-model="taskData.title"
            color="neutral-darken2"
            data-testid="task-title-input"
            class="mb-3 px-2"
            :label="t('task_title')"
            variant="outlined"
            hide-details
            single-line
          />
          <TextEditor
            v-model="descriptionData"
            :disabled="isReadOnly"
            :placeholder="t('enter_taskData_description')"
            @blur="handleUpdateTask"
          />
          <v-container class="tw-flex tw-flex-col tw-gap-[14px] px-">
            <v-row class="table-row">
              <v-col class="property-name">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-hard-drive
                </v-icon>
                <span class="text-body-1 text-neutral-darken1">{{ t('component') }}</span>
              </v-col>
              <v-col
                class="text-neutral-darken4 property-value"
              >
                <ComponentSelector
                  v-model="taskData.componentinproject"
                  :is-edit-mode="isEditMode"
                  :is-read-only="isReadOnly || props.selectedComponent !== undefined"
                  @update:model-value="handleUpdateTask"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="taskData.componentinproject !== undefined"
              class="table-row"
            >
              <v-col
                class="property-name"
              >
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-sparkles
                </v-icon>
                <span class="text-body-1 text-neutral-darken1">{{ t('analysis') }}</span>
              </v-col>
              <v-col class="text-neutral-darken4 property-value">
                <AnalysisResultSelector
                  v-model="taskData.analysis_result_id"
                  :component-in-project="taskData.componentinproject"
                  :is-edit-mode="isEditMode"
                  :is-read-only="isReadOnly || props.analysisResultId !== undefined"
                  :show-link-to-selected-result="!!task"
                  @update:model-value="handleUpdateTask"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="task"
              class="table-row"
            >
              <v-col class="property-name d-flex align-center">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-coins
                </v-icon>
                <v-tooltip
                  content-class="aedifion-tooltip"
                  location="left"
                >
                  <template
                    #activator="{ props:costSavingsTooltip }"
                  >
                    <span
                      v-bind="costSavingsTooltip"
                      class="text-body-1 text-neutral-darken1 d-block text-truncate"
                      v-text="t('cost_savings_potential')"
                    />
                  </template>
                  <span
                    v-text="t('cost_savings_potential')"
                  />
                </v-tooltip>
              </v-col>
              <v-col class="text-neutral-darken4 custom-readonly-input property-value">
                <span
                  class="text-body-1 tw-font-semibold pl-2"
                >{{ costSavings }}</span>
              </v-col>
            </v-row>
            <v-row class="table-row">
              <v-col class="property-name">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-calendar-day
                </v-icon>
                <span class="text-body-1 text-neutral-darken1">{{ t('start_date') }}</span>
              </v-col>
              <v-col class="text-neutral-darken4 tw-font-semibold property-value">
                <v-menu
                  v-model="datePickerDialogs.start"
                  :offset="[1, 0]"
                  :close-on-content-click="false"
                >
                  <template #activator="{ props: startDateProps }">
                    <v-text-field
                      v-model="taskData.start"
                      :disabled="isReadOnly"
                      :model-value="taskData.start ? date.format(taskData.start, 'normalDateWithWeekday') : undefined"
                      hide-details
                      single-line
                      readonly
                      data-testid="start-date"
                      density="compact"
                      :label="task ? 'N/A' : t('enter_date')"
                      variant="plain"
                      :class="['mt-0 pt-0 custom-input body-1-text', { 'no-decoration': task }, { 'empty-state-label': !task }]"
                      v-bind="startDateProps"
                    />
                  </template>
                  <v-date-picker
                    v-model="taskData.start"
                    width="auto"
                    @update:model-value="handleUpdateTask"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="table-row">
              <v-col class="property-name">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-calendar-check
                </v-icon>
                <span class="text-body-1 text-neutral-darken1">{{ t('end_date') }}</span>
              </v-col>
              <v-col class="tw-font-semibold text-neutral-darken4 property-value">
                <v-menu
                  v-model="datePickerDialogs.end"
                  :offset="[1, 0]"
                  :close-on-content-click="false"
                >
                  <template #activator="{ props: endDateProps }">
                    <v-text-field
                      :model-value="taskData.end? date.format(taskData.end, 'normalDateWithWeekday') : undefined"
                      :disabled="isReadOnly"
                      hide-details
                      density="compact"
                      data-testid="end-date"
                      single-line
                      readonly
                      :label="task ? 'N/A' : t('enter_date')"
                      variant="plain"
                      :class="['mt-0 pt-0 custom-input body-1-text', { 'no-decoration': task }, { 'empty-state-label': !task }]"
                      v-bind="endDateProps"
                    />
                  </template>
                  <v-date-picker
                    v-model="taskData.end"
                    width="auto"
                    @update:model-value="handleUpdateTask"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="table-row">
              <v-col class="property-name d-flex align-center">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-user-circle
                </v-icon>
                <v-tooltip
                  content-class="aedifion-tooltip"
                  location="left"
                >
                  <template
                    #activator="{ props:assigneeTooltip }"
                  >
                    <span
                      v-bind="assigneeTooltip"
                      class="text-body-1 text-neutral-darken1 d-block text-truncate"
                      v-text="t('form_fields.assignee')"
                    />
                  </template>
                  <span
                    v-text="t('form_fields.assignee')"
                  />
                </v-tooltip>
              </v-col>
              <v-col
                class="text-neutral-darken4 property-value"
              >
                <UserSelector
                  v-model="taskData.assignee"
                  :is-read-only="isReadOnly || !isCompanyAdmin"
                  :is-edit-mode="isEditMode"
                  @update:model-value="handleUpdateTask"
                />
              </v-col>
            </v-row>
            <v-row class="table-row">
              <v-col class="property-name">
                <v-icon
                  size="14"
                  class="mr-3"
                  color="neutral-darken1"
                >
                  far fa-bars-progress
                </v-icon>
                <span class="text-body-1 text-neutral-darken1">{{ t('status') }}</span>
              </v-col>
              <v-col class="py-0 property-value">
                <v-select
                  v-model="taskData.status"
                  :disabled="isReadOnly"
                  hide-selected
                  data-testid="status-selector"
                  menu-icon="fal fa-angle-down"
                  density="compact"
                  flat
                  variant="plain"
                  hide-details="auto"
                  :items="statusOptions"
                  :menu-props="{
                    'offset': [2, 0],
                    contentClass: 'aedifion-box-shadow tw-min-w-fit tw-max-w-fit'
                  }"
                  item-title="text"
                  class="custom-input"
                  item-value="value"
                  @update:model-value="handleUpdateTask"
                >
                  <template #selection="{ item }">
                    <StatusChip :status="item.value">
                      {{ item.title }}
                    </StatusChip>
                  </template>
                  <template #item="{ item, props: statusSelectionProps }">
                    <v-list-item
                      v-bind="statusSelectionProps"
                    >
                      <StatusChip :status="item.raw.value">
                        {{ item.raw.text }}
                      </StatusChip>
                      <template #title />
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row
              v-if="!task"
              class="table-row"
            >
              <v-col>
                <v-btn
                  color="primary-darken2"
                  class="mt-6"
                  data-testid="create-task-button"
                  :disabled="!isNewTaskValid"
                  @click="onCreateTask"
                >
                  {{ t('create_taskData') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
        <v-divider class="border-opacity-100" />
        <TaskComments
          v-if="task"
          :comments="comments"
          :is-read-only="isReadOnly"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="sass" scoped>
  .analysis-redirect-button:hover
    background-color: rgb(var(--v-theme-neutral-lighten1))

  :deep(.v-field--disabled)
    opacity: 1
  :deep(.v-input--disabled .v-field__append-inner)
    display: none
  .custom-input
    margin-bottom: 1px !important
    :deep(.v-field__input, textarea):not(.v-textarea__sizer)
      height: fit-content
      padding: 8px !important
      z-index: 100 !important
    &:not(.v-textarea)
      :deep(.v-field__input)
        padding: 0 8px !important
        height: 40px !important
    :deep(.v-field)
      border: 1px solid transparent !important
      &:hover:not(.v-field--focused)
        background-color: rgb(var(--v-theme-neutral-lighten2)) !important
        border-radius: 4px !important
    :deep(.v-field--focused)
      border-color: rgb(var(--v-theme-neutral-darken2)) !important
      border-radius: 4px !important
      font-weight: 400 !important
    :deep(.v-label)
      text-decoration: underline !important
      padding: 0 8px !important
      color: rgb(var(--v-theme-neutral-darken3)) !important
      opacity: 1
      font-weight: 600 !important

  .no-decoration
    :deep(.v-label)
      text-decoration: none !important

  .empty-state-label
    :deep(.v-label)
      font-weight: 500 !important
      color: rgb(var(--v-theme-neutral-darken1)) !important

  .custom-readonly-input
    height: 40px !important
    display: flex
    align-items: center

  .h4-text
    :deep(.v-field)
      input, textarea
        font-size: 24px
        font-weight: 600
        line-height: 32px

  .body-1-text
    :deep(.v-field)
      input
        font-size: .875rem !important
        line-height: 1.25rem
      &:not(.v-field--focused)
        font-weight: 600

  .priority-selector
    :deep(.v-field__append-inner)
      margin: auto !important

  .table-row
    @apply tw-flex-nowrap tw-max-h-[40px] tw-items-center tw-mt-0 tw-whitespace-nowrap

  .property-name
    @apply tw-max-w-[160px] tw-leading-5 tw-py-0 tw-h-[40px] tw-content-center tw-overflow-hidden

  .property-value
    @apply tw-py-0 tw-h-[40px] tw-content-center
</style>

<i18n lang="json" locale="de">
  {
    "component": "Komponente",
    "analysis": "Analyse",
    "cost_savings_potential": "Jährl. Einsparpot.",
    "start_date": "Startdatum",
    "end_date": "Enddatum",
    "enter_date": "Datum eingeben",
    "status": "Status",
    "delete_taskData": "Aufgabe löschen",
    "status_options": {
      "open": "Offen",
      "done": "Erledigt",
      "doing": "In Bearbeitung",
      "review": "Zur Überprüfung",
      "rejected": "Abgelehnt",
      "failed": "Fehlgeschlagen"
    },
    "task_title": "Aufgabentitel",
    "enter_taskData_description": "Aufgabenbeschreibung eingeben",
    "create_taskData": "Aufgabe erstellen"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "component": "Component",
    "analysis": "Analysis",
    "cost_savings_potential": "Cost savings potential yearly",
    "start_date": "Start date",
    "end_date": "End date",
    "enter_date": "Enter date",
    "status": "Status",
    "delete_taskData": "Delete task",
    "status_options": {
      "open": "Open",
      "done": "Done",
      "doing": "Doing",
      "review": "Review",
      "rejected": "Rejected",
      "failed": "Failed"
    },
    "task_title": "Task Title",
    "enter_taskData_description": "Enter task description",
    "create_taskData": "Create task"
  }
</i18n>
